import React from "react";
import PropTypes from "prop-types";
import Layout from "../../../../layouts/Layout";
import Seo from "../../../seo";
import Intro from "../../../common/Intro";
import Breadcrumb from "../../../common/Breadcrumb";
import BasicSection from "../../../common/BasicSection";
import Technologies from "../../../common/Technologies";
import Advantages from "../../../common/Advantages";
import HowWeWork from "../../../common/HowWeWork";
import { getHrefs } from "../../../../utils/langService";
import { getShareImage } from "../../../../utils/getShareImage";

import imgWww from "../../../../images/www.jpg";
import imgWordPress from "../../../../images/wordpress.jpg";
import imgProgramming from "../../../../images/programming.jpg";
import imgCopywritingDesktop from "../../../../images/copywriting-desktop.jpg";
import imgLawyer from "../../../../images/lawyer.jpg";
import imgExperience from "../../../../images/common/experience.svg";
import imgInnovation from "../../../../images/common/innovation.svg";
import imgLightning from "../../../../images/common/lightning.svg";
import imgHeart from "../../../../images/common/heart.svg";

const Websites = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  const { pages } = getHrefs(lang);

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} pages={[{ name: servicesT.software.title }]} />

      <div className="Software">
        <BasicSection t={t.mainSection} image={imgWww} />

        <BasicSection t={t.wordPress} image={imgWordPress} reverse />

        <BasicSection t={t.customSolutions} image={imgProgramming} />

        <BasicSection t={t.copywriters} image={imgCopywritingDesktop} smallImage />

        <BasicSection t={t.lawyers} image={imgLawyer} smallImage />

        <Technologies t={t.technologies} />

        <Advantages t={t.advantages} images={[imgExperience, imgInnovation, imgLightning, imgHeart]} nopadding />

        <HowWeWork t={t.howWeWork} link={`/${lang}/${pages[4]}/`} />
      </div>
    </Layout>
  );
};

Websites.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Websites;
